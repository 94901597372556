export enum AnswerVariants {
  AGAIN,
  HARD,
  KNOW,
  EASY,
}

export interface UserData {
  email: string;
  password: string;
  rePassword?: string;
  invite: string;
}

export interface Word {
  word: string;
  translate: string;
  wordContext?: string;
  translateContext?: string;
}

export interface WordExt extends Word {
  _id: string;
  dictionary: string;
  isWordNew: boolean;
  nextRepeat: Date;
  interval: number;
  isWordNewBack: boolean;
  nextRepeatBack: Date;
  intervalBack: number;
}

export interface Dictionary {
  id: string;
  name: string;
}
export interface AllUserData {
  words: WordExt[];
  dicts: Dictionary[];
  userCurrentDict: Dictionary;
}

export interface WordDto extends Word {
  index: number;
  _id: string;
}

export type AnswerDto = {
  id: string;
  isWordNew: boolean;
  nextRepeat: Date;
  interval: number;
  isWordNewBack: boolean;
  nextRepeatBack: Date;
  intervalBack: number;
  answer: AnswerVariants;
};
export interface ErrorObj {
  message: string;
  status: number;
}
export interface ErrorNoWords extends ErrorObj {
  nextDate: string;
  nextTime: string;
}

export interface ErrorPayload {
  nextDate: Date;
}

export type FailedQueue = {
  resolve: (token: string) => void;
  reject: (error: unknown) => void;
};
