import React from 'react';
import { Alert, Button, CircularProgress, Snackbar, TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { authSlice } from 'store/reducers/AuthSlice';
import { signIn } from 'store/reducers/AuthActionCreators';
import validator from 'validator';
import { useForm } from 'react-hook-form';
import { UserData } from 'types/types';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ReactComponent as LogoFull } from '../../assets/svg/logoFull.svg';
import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';

export const SignIn = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<UserData>();
  const dispatch = useAppDispatch();
  const { isError, errorMessage, isPending } = useAppSelector((state) => state.auth);
  const { t } = useTranslation();

  const { closeError } = authSlice.actions;

  const onSubmit = (userData: UserData) => {
    dispatch(signIn(userData));
  };
  const handleClose = () => {
    dispatch(closeError());
  };
  console.log('SignIn');

  return (
    <div className="signin">
      <div className="logo">
        <LogoFull />
      </div>

      <div className="tip">
        <MenuBookTwoToneIcon className="tip__icon" />
        <NavLink to="/help" className="tip__link">
          {t('tips.howItWorks')}
        </NavLink>
      </div>

      <form className="signin-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="signin__wrapper">
          <TextField
            type="email"
            inputProps={{ inputMode: 'email', autoCapitalize: 'none', autoCorrect: 'off' }}
            label="E-mail"
            variant="outlined"
            {...register('email', {
              required: t('sign.emailRequired') as string,
              validate: (value) => validator.isEmail(value) || (t('sign.enterEmail') as string),
            })}
            error={!!errors.email}
            helperText={errors.email?.message}
          />
          <TextField
            type="text"
            inputProps={{ autoCapitalize: 'none', autoCorrect: 'off' }}
            label={t('sign.password')}
            variant="outlined"
            {...register('password', {
              required: t('sign.passwordRequired') as string,
              validate: (value) =>
                validator.isLength(value, { min: 6, max: 100 }) ||
                (t('sign.minCharacters_withValue', { val: '6 - 100' }) as string),
            })}
            error={!!errors.password}
            helperText={errors.password?.message}
          />
        </div>
        {isPending ? (
          <CircularProgress />
        ) : (
          <Button
            className="signin__button"
            variant="contained"
            type="submit"
            disabled={isPending}
            fullWidth
          >
            {t('sign.signIn')}
          </Button>
        )}
        <NavLink to="/signup" className="signin__link">
          {t('sign.registration')}
        </NavLink>
        {/* <a href="/signup" className="signin__link">
          Registration
        </a> */}
      </form>
      <Snackbar
        open={isError}
        autoHideDuration={4000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        sx={{ bottom: { xs: 20 } }}
      >
        <Alert severity="error" onClose={handleClose}>
          {errorMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};
