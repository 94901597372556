import { Button, IconButton } from '@mui/material';
import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { ReactComponent as LogoSettingsBtn } from '../../../assets/svg/logo.svg';
import { BurgerMenu } from './BurgerMenu/BurgerMenu';
import { useTranslation } from 'react-i18next';
import { HelpDrawer } from 'components/HelpDrawer/HelpDrawer';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { dictionarySlice } from 'store/reducers/DictionarySlice';
import { SelectDict } from './SelectDict/SelectDict';
import { AddNewDict } from './AddNewDict/AddNewDict';

export const BaseBlock: FC<{ isViewDisabled: boolean }> = ({ isViewDisabled }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const { isDictAdding } = useAppSelector((state) => state.dictionary);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { toggleDictAdding } = dictionarySlice.actions;
  const { t } = useTranslation();
  const goViewDictionary = () => {
    navigate('/');
  };
  const goLearn = () => {
    navigate('/learn');
  };

  const openBurgerMenu = (open: boolean) => () => {
    setMenuOpen(open);
  };

  const addDictHandler = () => dispatch(toggleDictAdding(true));

  const addDictButton = (
    <IconButton sx={{ mr: 'auto' }} onClick={addDictHandler}>
      <AddCircleOutlineIcon className="add-dict-btn" sx={{ fontSize: 28 }} />
    </IconButton>
  );
  return (
    <div className="top-block">
      <div className="top-block__cell">
        {!isDictAdding ? (
          <>
            <SelectDict />
            {addDictButton}
          </>
        ) : (
          <AddNewDict />
        )}

        <button
          className={!isMenuOpen ? 'top-block__settings-btn' : 'top-block__settings-btn visible'}
          onClick={openBurgerMenu(!isMenuOpen)}
        >
          <LogoSettingsBtn />
        </button>
      </div>
      <div className="top-block__buttons">
        <Button variant="contained" disabled={isViewDisabled} onClick={goViewDictionary}>
          {t('topBlock.view')}
        </Button>
        <Button variant="contained" onClick={goLearn}>
          {t('topBlock.learn')}
        </Button>
      </div>
      <BurgerMenu isMenuOpen={isMenuOpen} setMenuOpen={setMenuOpen} />
      <HelpDrawer />
    </div>
  );
};
