import { ApiError } from 'http/ApiError';
import { ErrorNoWords } from 'types/types';

export default (error: ApiError): ErrorNoWords => {
  return {
    message: error.message ?? "Can't get learning words",
    status: error.apiStatus,
    nextDate: error.payload?.nextDate
      ? new Date(error.payload.nextDate).toLocaleDateString([], {
          month: '2-digit',
          day: 'numeric',
          year: 'numeric',
        })
      : '',
    nextTime: error.payload?.nextDate
      ? new Date(error.payload.nextDate).toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        })
      : '',
  };
};
