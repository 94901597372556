import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { store } from 'store/store';
import App from './App';
import { ThemeProvider } from '@emotion/react';
import { createTheme } from '@mui/material';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const theme = createTheme({
  palette: {
    primary: {
      main: '#0288d1',
    },
  },

  components: {
    // Name of the component
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          borderRadius: 28,
          textTransform: 'none',
          minWidth: '70px',
          // padding: '7px 20px',
        },

        outlinedSizeLarge: {
          padding: '7px 20px',
          // backgroundColor: '#d10232',
        },
        outlinedSuccess: {
          color: '#2e8802d9',
          '&:hover': {
            color: '#5bc706d4',
          },
          borderColor: '#0288d180',
        },
        outlinedError: {
          borderColor: '#0288d180',

          color: '#d10232',
          '&:hover': {
            color: '#ff4470',
          },
        },
      },
    },

    MuiButtonGroup: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
          borderRadius: 28,
        },
        // grouped: {
        //   containedSuccess: {
        //     color: '#93ff93',
        //   },
        //   containedError: {
        //     color: '#fec6c6',
        //   },
        //   boxShadow: 'none',
        //   '&:hover': {
        //     boxShadow: 'none',
        //   },
        // },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          margin: '10px',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          '@media (max-width: 370px)': {
            '& .MuiStack-root': {
              minWidth: '240px',
            },
          },
        },
      },
    },
  },

  // shape: {
  //   borderRadius: 8,
  // },
});
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>
);
