import { Tooltip, TooltipProps, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

export const TooltipStyled = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#ebf5fb',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#ebf5fb',
    color: 'rgba(0, 0, 0, 0.87)',
    // maxWidth: 240,
    fontSize: theme.typography.pxToRem(14),
    border: '0',
  },
}));
