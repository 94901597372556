import React from 'react';
import { getToken } from 'Utils/storage';
import { useAppSelector } from 'hooks/redux';
import { Outlet, Navigate } from 'react-router-dom';

export const PrivateAuthRoutes = () => {
  const { isAuth } = useAppSelector((state) => state.auth);
  const token = getToken();

  return token && isAuth ? <Navigate to="/" /> : <Outlet />;
};
