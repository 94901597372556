import { useAppSelector } from 'hooks/redux';
import React, { FC } from 'react';
import { WordExt } from 'types/types';

export const FrontSide: FC<{ currentWord: WordExt }> = ({ currentWord }) => {
  const { isLearnBack } = useAppSelector((state) => state.settings);

  const word = isLearnBack ? currentWord.translate : currentWord.word;
  const context = isLearnBack ? currentWord.translateContext : currentWord.wordContext;

  return (
    <>
      <div className="learn__word">{word}</div>
      <div className="learn__context word">{context}</div>
    </>
  );
};
