import { Button, ButtonGroup, CircularProgress, Slide } from '@mui/material';
import createAnswerDto from 'Utils/createAnswerDto';
import { TopBlock } from 'components/TopBlock/TopBlock';
import { useAppDispatch, useAppSelector } from 'hooks/redux';

import React, { useEffect, useRef, useState } from 'react';
import { getGroupWords } from 'store/reducers/LearnActionCreators';
import { learnSlice } from 'store/reducers/LearnSlice';
import { AnswerVariants } from 'types/types';
import { FrontSide } from './FrontSide/FrontSide';
import { BackSide } from './BackSide/BackSide';
import { useTranslation } from 'react-i18next';
import { TooltipStyled } from 'components/TooltipStyled/TooltipStyled';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Zoom from '@mui/material/Zoom';

export const Learn = () => {
  const [isTranslate, setIsTranslate] = useState(false);
  const dispatch = useAppDispatch();
  const { words, wordsDone, isPending, errorNoWords } = useAppSelector((state) => state.learn);
  const isNeedTips = useAppSelector((state) => state.settings.isNeedTips);

  const { getNextWord, setNoWordsToday } = learnSlice.actions;

  useEffect(() => {
    dispatch(setNoWordsToday(false));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (words.length === 0) {
      dispatch(getGroupWords(wordsDone));
    }
    // eslint-disable-next-line
  }, [words, dispatch]);

  const containerRef = useRef(null);
  const { t } = useTranslation();

  const currentWord = words[words.length - 1];
  const nextWord = (answer: AnswerVariants) => {
    setIsTranslate(false);
    const answerDto = createAnswerDto(currentWord, answer);

    setTimeout(() => {
      dispatch(getNextWord(answerDto));
    }, 200);
  };
  let errorMessage: JSX.Element = <></>;
  if (errorNoWords) {
    if (errorNoWords.status === 44) {
      errorMessage = (
        <span>
          {t('learn.noWordsForLearning')}
          <br />
          {t('learn.addWordsToDict')}
        </span>
      );
    }
    if (errorNoWords.status === 33) {
      errorMessage = (
        <span>
          {t('learn.noWordsForLearning')}
          <br />
          <br />
          {t('learn.welcome')} <span className="bold"> {errorNoWords.nextDate}</span>{' '}
          {t('learn.after')}
          <span className="bold"> {errorNoWords.nextTime}</span>
        </span>
      );
    }
  }
  const { innerWidth } = window;
  const buttonsGroupSIze = innerWidth > 367 ? 'large' : 'medium';

  return (
    <div className="learn">
      <TopBlock isViewDisabled={false} isLearnBlock={true} />

      <div className="learn__main-block" ref={containerRef}>
        {!isPending && words.length > 0 ? (
          <>
            <div className="learn__words-block">
              <FrontSide currentWord={currentWord} />
              <Slide
                direction="left"
                in={isTranslate}
                mountOnEnter
                container={containerRef.current}
              >
                <div className="learn__divider" />
              </Slide>
              <Slide
                direction="right"
                in={isTranslate}
                mountOnEnter
                container={containerRef.current}
              >
                <div className="learn__translate-block">
                  <BackSide currentWord={currentWord} />
                </div>
              </Slide>
            </div>
            <div className="learn__button-block">
              {!isTranslate ? (
                <Button
                  size="large"
                  variant="contained"
                  onClick={() => setIsTranslate(!isTranslate)}
                >
                  {t('learn.answer.show')}
                </Button>
              ) : (
                <>
                  {isNeedTips && (
                    <div className="learn__tip">
                      <TooltipStyled
                        enterTouchDelay={0}
                        leaveTouchDelay={6000}
                        TransitionComponent={Zoom}
                        placement="top-start"
                        title={
                          <span className="tip__tooltip">
                            {t('tips.message.learnButtonsTip1')}
                            <br />
                            {t('tips.message.learnButtonsTip2')}
                            <br />
                            {t('tips.message.learnButtonsTip3')}
                            <br />
                            {t('tips.message.learnButtonsTip4')}
                            <br /> <br />
                            <span className="tip__remark">{t('tips.message.turnOffTips')}</span>
                          </span>
                        }
                        arrow
                      >
                        <HelpOutlineIcon
                          sx={{ fontSize: 20, mr: 0.5 }}
                          className="menu__tip-icon"
                        />
                      </TooltipStyled>
                      {t('tips.whatsThis')}
                    </div>
                  )}
                  <ButtonGroup size={buttonsGroupSIze} aria-label="text button group">
                    <Button
                      variant="contained"
                      sx={{ color: '#fec6c6' }}
                      onClick={() => nextWord(AnswerVariants.AGAIN)}
                    >
                      {t('learn.answer.again')}
                    </Button>
                    <Button variant="contained" onClick={() => nextWord(AnswerVariants.HARD)}>
                      {t('learn.answer.hard')}
                    </Button>
                    <Button
                      variant="contained"
                      sx={{ color: '#93ff93' }}
                      onClick={() => nextWord(AnswerVariants.KNOW)}
                    >
                      {t('learn.answer.know')}
                    </Button>
                    <Button variant="contained" onClick={() => nextWord(AnswerVariants.EASY)}>
                      {t('learn.answer.easy')}
                    </Button>
                  </ButtonGroup>
                </>
              )}
            </div>
          </>
        ) : isPending ? (
          <CircularProgress />
        ) : (
          <div className="learn__no-words">{errorMessage}</div>
        )}
      </div>
    </div>
  );
};
