import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { AnswerDto, ErrorNoWords, WordExt } from 'types/types';
import { getGroupWords, saveLearnProgress } from './LearnActionCreators';

export interface LearnState {
  words: WordExt[];
  wordsDone: AnswerDto[];
  isPending: boolean;
  isNoWordsToday: boolean;
  errorNoWords?: ErrorNoWords;
}

const initialState: LearnState = {
  words: [],
  wordsDone: [],
  isPending: false,
  isNoWordsToday: false,
};

export const learnSlice = createSlice({
  name: 'learn',
  initialState,
  reducers: {
    setNoWordsToday: (state, action: PayloadAction<boolean>) => {
      state.isNoWordsToday = action.payload;
    },
    getNextWord: (state, action: PayloadAction<AnswerDto>) => {
      state.words.splice(
        state.words.findIndex((word) => word._id === action.payload.id),
        1
      );
      state.wordsDone.push(action.payload);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getGroupWords.fulfilled, (state, action) => {
        state.isPending = false;
        if (action.payload.length > 0) {
          state.words = action.payload;
        }
        state.wordsDone = [];
      })
      .addCase(getGroupWords.pending, (state) => {
        state.isPending = true;
      })
      .addCase(getGroupWords.rejected, (state, action) => {
        state.isPending = false;
        state.isNoWordsToday = true;
        state.wordsDone = [];
        state.errorNoWords = action.payload as ErrorNoWords;
      })
      .addCase(saveLearnProgress.fulfilled, (state) => {
        state.isPending = false;
        state.wordsDone = [];
        state.words = [];
        console.log('updated');
      })
      .addCase(saveLearnProgress.pending, (state) => {
        state.isPending = true;
      })
      .addCase(saveLearnProgress.rejected, (state, action) => {
        state.isPending = false;
        console.log(action.payload);
      });
  },
});

export default learnSlice.reducer;
