import { Button } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { saveLearnProgress } from 'store/reducers/LearnActionCreators';
import { useTranslation } from 'react-i18next';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import { settingsSlice } from 'store/reducers/SettingsSlice';
import { HelpDrawer } from 'components/HelpDrawer/HelpDrawer';

export const LearnBlock = () => {
  const currentDict = useAppSelector((state) => state.dictionary.currentDict);
  const isNeedTips = useAppSelector((state) => state.settings.isNeedTips);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { toggleHelpDrawer } = settingsSlice.actions;

  const { t } = useTranslation();

  const goExit = () => {
    dispatch(saveLearnProgress());
    navigate('/');
  };

  return (
    <div className="top-block">
      <div className="top-block__cell">
        <div className="top-block__dict-name">{currentDict.name}</div>
        {isNeedTips && (
          <div className="top-block__tip">
            <LightbulbOutlinedIcon className="tip__icon" />
            <div onClick={() => dispatch(toggleHelpDrawer(true))} className="tip__link">
              {t('tips.bestWayToUseApp')}
            </div>
          </div>
        )}
      </div>
      <div className="top-block__buttons">
        <Button variant="contained" onClick={goExit}>
          {t('topBlock.finish')}
        </Button>
      </div>

      <HelpDrawer />
    </div>
  );
};
