import { createAsyncThunk } from '@reduxjs/toolkit';
import { AnswerDto, WordExt } from 'types/types';
import { RootState } from 'store/store';
import { api } from 'http/http';
import { ApiError } from 'http/ApiError';
import createNoWordsDto from 'Utils/createNoWordsDto';

export const getGroupWords = createAsyncThunk<WordExt[], AnswerDto[], { state: RootState }>(
  'learn/fetchWordsGroup',
  async (wordsDone: AnswerDto[], thunkAPI) => {
    try {
      const side = thunkAPI.getState().settings.isLearnBack ? 'back' : 'front';
      const response = await api.post<WordExt[]>(`/learn/getWords/${side}`, wordsDone);
      return response.data;
    } catch (error) {
      if (error instanceof ApiError) {
        const errorNoWords = createNoWordsDto(error);
        return thunkAPI.rejectWithValue(errorNoWords);
      }

      const unexpectedError = ApiError.UnexpectedError();
      const payload = createNoWordsDto(unexpectedError);
      return thunkAPI.rejectWithValue(payload);
    }
  },
  {
    condition: (_, { getState }) => {
      const { learn } = getState();
      return !learn.isPending;
    },
  }
);
export const saveLearnProgress = createAsyncThunk<unknown, undefined, { state: RootState }>(
  'learn/saveLearnProgress',
  async (_, thunkAPI) => {
    try {
      const side = thunkAPI.getState().settings.isLearnBack ? 'back' : 'front';
      const response = await api.post<WordExt[]>(
        `/learn/updateWords/${side}`,
        thunkAPI.getState().learn.wordsDone
      );

      return response.data;
    } catch (error) {
      if (error instanceof ApiError) return thunkAPI.rejectWithValue(error.message);
      return thunkAPI.rejectWithValue("Can't update learning words");
    }
  }
);
