import React, { FC } from 'react';
import { BaseBlock } from './BaseBlock/BaseBlock';
import { LearnBlock } from './LearnBlock/LearnBlock';

type TopBlockProps = {
  isViewDisabled: boolean;
  isLearnBlock?: boolean;
};

export const TopBlock: FC<TopBlockProps> = ({ isViewDisabled, isLearnBlock }) => {
  return !isLearnBlock ? <BaseBlock isViewDisabled={isViewDisabled} /> : <LearnBlock />;
};
