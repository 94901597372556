import { AnswerDto, AnswerVariants, WordExt } from 'types/types';

export default (word: WordExt, answer: AnswerVariants): AnswerDto => {
  return {
    id: word._id,
    isWordNew: word.isWordNew,
    nextRepeat: word.nextRepeat,
    interval: word.interval,
    isWordNewBack: word.isWordNewBack,
    nextRepeatBack: word.nextRepeatBack,
    intervalBack: word.intervalBack,
    answer: answer,
  };
};
