import { ApiError } from 'http/ApiError';
import { unAuthorizedApi } from 'http/http';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

type Message = {
  message: string;
  success: boolean;
};

export const Activation = () => {
  const params = useParams<{ token: string }>();
  const [message, setMessage] = useState<Message>({ message: '', success: false });
  const isActivated = useRef(false);
  const { t } = useTranslation();

  useEffect(() => {
    const activate = async () => {
      try {
        isActivated.current = true;
        await unAuthorizedApi.get(`/auth/activate/${params.token}`);
        setMessage({ message: t('activate.success'), success: true });
      } catch (error) {
        if (error instanceof ApiError) {
          if (error.status === 400) {
            setMessage({ message: t('activate.error'), success: false });
          } else setMessage({ message: t('activate.error'), success: false });
        } else setMessage({ message: t('activate.error'), success: false });
      }
    };
    if (!isActivated.current) activate();
  }, [isActivated, params.token, t]);
  const getMessageColor = (status: boolean) => {
    if (status) return 'green';
    return 'red';
  };

  return (
    <div className="activation">
      {!!message.message && (
        <div style={{ color: getMessageColor(message.success) }}>{message.message}</div>
      )}
      {message.success && (
        <div>
          <a className="activation__signin-link" href="/signin">
            {t('sign.logIn')}
          </a>
        </div>
      )}
    </div>
  );
};
