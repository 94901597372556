import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserData } from 'types/types';

import { unAuthorizedApi } from 'http/http';
import { ApiError } from 'http/ApiError';

export const signUp = createAsyncThunk('auth/signUp', async (userData: UserData, thunkAPI) => {
  try {
    const response = await unAuthorizedApi.post('/auth/register', userData);
    return response.data;
  } catch (error) {
    if (error instanceof ApiError) return thunkAPI.rejectWithValue(error.message);
    return thunkAPI.rejectWithValue("Can't register user");
  }
});
export const signIn = createAsyncThunk('auth/signIn', async (userData: UserData, thunkAPI) => {
  try {
    const response = await unAuthorizedApi.post('/auth/login', userData);
    return response.data;
  } catch (error) {
    if (error instanceof ApiError) return thunkAPI.rejectWithValue(error.message);
    return thunkAPI.rejectWithValue("Can't login");
  }
});
