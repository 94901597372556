import React from 'react';
import './sass/style.scss';
import './i18n';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { SignUp } from 'Pages/SignUp/SignUp';
import { Layout } from 'components/Layout';
import { SignIn } from 'Pages/SignIn/SignIn';
import { PrivateAuthRoutes } from 'common/PrivateRoutes/PrivateAuthRoutes';
import { PrivateRoutes } from 'common/PrivateRoutes/PrivateRoutes';
import { Dictionary } from 'Pages/Dictionary/Dictionary';
import { AddWord } from 'Pages/AddWord/AddWord';
import { Learn } from 'Pages/Learn/Learn';
import { Activation } from 'Pages/Activation/Activation';
import { Help } from 'Pages/Help/Help';

export const App = () => {
  console.log('App');

  return (
    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route element={<PrivateRoutes />}>
            <Route path="/" element={<Dictionary />} />
            <Route path="/addword" element={<AddWord />} />
            <Route path="/learn" element={<Learn />} />
          </Route>
          <Route element={<PrivateAuthRoutes />}>
            <Route path="/signup" element={<SignUp />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/activation/:token" element={<Activation />} />
          </Route>
          <Route path="/help" element={<Help />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default App;
