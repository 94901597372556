import React from 'react';
import { getToken } from 'Utils/storage';
import { Outlet } from 'react-router-dom';
import { useAppSelector } from 'hooks/redux';
import { signOut } from 'Utils/utils';

export const PrivateRoutes = () => {
  const isAuth = useAppSelector((state) => state.auth.isAuth);
  const token = getToken();
  if (!token || !isAuth) signOut();
  return token && isAuth ? <Outlet /> : <></>;
};
