import { TextField } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import React, { useState } from 'react';
import { dictionarySlice } from 'store/reducers/DictionarySlice';
import { createDict } from 'store/reducers/DictionaryActionCreators';

export const AddNewDict = () => {
  const [newDictName, setDictName] = useState('');
  const { isDictAdding, isDictCreating } = useAppSelector((state) => state.dictionary);
  const dispatch = useAppDispatch();
  const { toggleDictAdding } = dictionarySlice.actions;

  const changeDictNameHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDictName(event.target.value);
  };
  const closeAddingDict = () => {
    dispatch(toggleDictAdding(false));
    setDictName('');
  };
  const addDictHandler = async (event: React.KeyboardEvent | React.MouseEvent) => {
    if (!isDictAdding) {
      dispatch(toggleDictAdding(true));
      return;
    }
    if (
      event.type === 'keyup' &&
      (event as React.KeyboardEvent<HTMLInputElement>).key === 'Escape'
    ) {
      closeAddingDict();
      return;
    }
    if (
      event.type === 'keyup' &&
      (event as React.KeyboardEvent<HTMLInputElement>).key !== 'Enter'
    ) {
      return;
    }
    await dispatch(createDict(newDictName));
    setDictName('');
  };

  return (
    <>
      <TextField
        value={newDictName}
        size="small"
        label="Enter dictionary name"
        variant="outlined"
        onChange={changeDictNameHandler}
        onKeyUp={addDictHandler}
        onBlur={() => closeAddingDict()}
        disabled={isDictCreating}
        autoFocus
      />
    </>
  );
};
