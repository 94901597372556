import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getLearningSide, getNeedTips, saveLearningSide, saveNeedTips } from 'Utils/storage';

export interface SettingsState {
  isNeedTips: boolean;
  isLearnBack: boolean;
  isHelpOpen: boolean;
}

const initialState: SettingsState = {
  isNeedTips: getNeedTips(),
  isLearnBack: getLearningSide(),
  isHelpOpen: false,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    toggleHelpDrawer: (state, action: PayloadAction<boolean>) => {
      console.log('toggleHelpDrawer', action.payload);

      state.isHelpOpen = action.payload;
    },
    toggleNeedTips: (state, action: PayloadAction<boolean>) => {
      state.isNeedTips = action.payload;
      saveNeedTips(action.payload);
    },
    setLearningSide: (state, action: PayloadAction<boolean>) => {
      state.isLearnBack = action.payload;
      saveLearningSide(action.payload);
    },
  },
});

export default settingsSlice.reducer;
