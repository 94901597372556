import { ErrorPayload } from 'types/types';

export class ApiError extends Error {
  status: number;
  apiStatus: number;
  errors;
  payload?: ErrorPayload;

  constructor(
    status: number,
    message: string,
    apiStatus: number = status,
    errors = [],
    payload?: ErrorPayload
  ) {
    super(message);
    this.status = status;
    this.apiStatus = apiStatus;
    this.errors = errors;
    if (payload) this.payload = { nextDate: new Date(payload.nextDate) };
  }

  static UnauthorizedError = () => {
    return new ApiError(401, 'User is not authorized');
  };

  static BadRequest = (message: string, apiStatus = 400, errors = [], payload?: ErrorPayload) => {
    return new ApiError(400, message, apiStatus, errors, payload);
  };

  static Builder = (
    status: number,
    message: string,
    apiStatus = status,
    errors = [],
    payload?: ErrorPayload
  ) => {
    return new ApiError(status, message, apiStatus, errors, payload);
  };

  static UnexpectedError = () => {
    return new ApiError(500, 'Unexpected error');
  };

  static NotFound = () => {
    return new ApiError(404, 'Document not found');
  };

  static Forbidden = (message: string, apiStatus = 403, errors = []) => {
    return new ApiError(403, message, apiStatus, errors);
  };
}
