import { useAppSelector } from 'hooks/redux';
import React, { FC } from 'react';
import { WordExt } from 'types/types';

export const BackSide: FC<{ currentWord: WordExt }> = ({ currentWord }) => {
  const { isLearnBack } = useAppSelector((state) => state.settings);

  const word = isLearnBack ? currentWord.word : currentWord.translate;
  const context = isLearnBack ? currentWord.wordContext : currentWord.translateContext;

  return (
    <>
      <div className="learn__translate">{word}</div>
      <div className="learn__context translate">{context}</div>
    </>
  );
};
