import { getToken, saveToken } from 'Utils/storage';
import { signOut } from 'Utils/utils';
import axios from 'axios';
import { ApiError } from 'http/ApiError';
import { FailedQueue } from 'types/types';

let isRefreshing = false;
let failedQueue: FailedQueue[] = [];

const processQueue = (error: unknown | null, token: string | null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else if (token) {
      prom.resolve(token);
    }
  });
  failedQueue = [];
};

const handleError = (error: unknown) => {
  if (axios.isAxiosError(error)) {
    if (error.response?.status === 401) signOut();
    if (error.response) {
      const data = error.response.data;
      return ApiError.Builder(
        error.response.status,
        data.message ?? error.message,
        data.status ?? error.response.status,
        [],
        data.payload ?? null
      );
    }
  }
  return ApiError.UnexpectedError();
};

const unAuthorizedApi = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
  withCredentials: true,
});

unAuthorizedApi.interceptors.response.use(
  (config) => config,
  (error) => {
    throw handleError(error);
  }
);

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
  withCredentials: true,
});

api.interceptors.request.use((config) => {
  const token = getToken();
  if (!token) signOut();
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

api.interceptors.response.use(
  (config) => config,
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 401 && originalRequest && !originalRequest._isRetry) {
      if (isRefreshing) {
        try {
          const token = await new Promise<string>((resolve, reject) => {
            failedQueue.push({ resolve, reject });
          });
          saveToken(token);
          return api.request(originalRequest);
        } catch (error) {
          throw handleError(error);
        }
      }

      originalRequest._isRetry = true;
      isRefreshing = true;

      try {
        const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/auth/refresh`, {
          withCredentials: true,
        });

        saveToken(response.data.token);

        processQueue(null, response.data.token);
        return api.request(originalRequest);
      } catch (error) {
        processQueue(error, null);
        throw handleError(error);
      } finally {
        isRefreshing = false;
      }
    }
    throw handleError(error);
  }
);

export { api, unAuthorizedApi };
