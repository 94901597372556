import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Dictionary, ErrorObj, WordExt } from 'types/types';
import {
  createWord,
  deleteWord,
  getAllWords,
  updateWord,
  fetchUserData,
  changeCurrentDict,
  createDict,
} from './DictionaryActionCreators';

export interface DictionaryState {
  words: WordExt[];
  dicts: Dictionary[];
  currentDict: Dictionary;
  isPending: boolean;
  wordCreated: boolean;
  isNeedToFetchData: boolean;
  isDictAdding: boolean;
  isDictCreating: boolean;
  error?: ErrorObj;
}
const initialCurrentDict: Dictionary = {
  id: '',
  name: '',
};

const initialState: DictionaryState = {
  words: [],
  dicts: [],
  currentDict: initialCurrentDict,
  isPending: false,
  wordCreated: false,
  isNeedToFetchData: true,
  isDictAdding: false,
  isDictCreating: false,
};

export const dictionarySlice = createSlice({
  name: 'dictionary',
  initialState,
  reducers: {
    closeError: (state) => {
      state.error = undefined;
    },
    toggleDictAdding: (state, action: PayloadAction<boolean>) => {
      state.isDictAdding = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getAllWords.fulfilled, (state, action) => {
        state.isPending = false;
        state.words = action.payload.reverse();
        state.isNeedToFetchData = false;
      })
      .addCase(getAllWords.pending, (state) => {
        state.isPending = true;
      })
      .addCase(getAllWords.rejected, (state, action) => {
        state.isPending = false;
        state.words = [];
        state.isNeedToFetchData = false;
        console.log(action.payload);
      })
      .addCase(fetchUserData.fulfilled, (state, action) => {
        state.isPending = false;
        state.words = action.payload.words.reverse();
        state.dicts = action.payload.dicts;
        state.currentDict = action.payload.userCurrentDict ?? initialCurrentDict;
        state.isNeedToFetchData = false;
      })
      .addCase(fetchUserData.pending, (state) => {
        state.isPending = true;
      })
      .addCase(fetchUserData.rejected, (state) => {
        state.isPending = false;
        state.words = [];
        state.dicts = [];
        state.currentDict = initialCurrentDict;
        state.isNeedToFetchData = false;
      })
      .addCase(changeCurrentDict.fulfilled, (state, action: PayloadAction<string>) => {
        state.isPending = false;
        state.isNeedToFetchData = true;
        state.currentDict =
          state.dicts.find((dict: Dictionary) => dict.id === action.payload) ?? initialCurrentDict;
      })
      .addCase(changeCurrentDict.pending, (state) => {
        state.isPending = true;
      })
      .addCase(changeCurrentDict.rejected, (state) => {
        state.isPending = false;
        state.currentDict = initialCurrentDict;
      })
      .addCase(createDict.fulfilled, (state, action) => {
        state.isDictCreating = false;
        state.isDictAdding = false;
        state.words = [];
        state.dicts.push(action.payload);
        state.currentDict = action.payload;
      })
      .addCase(createDict.pending, (state) => {
        state.isDictCreating = true;
      })
      .addCase(createDict.rejected, (state, action) => {
        state.isDictCreating = false;
        state.isDictAdding = false;
        console.log(action.payload);
      })
      .addCase(createWord.fulfilled, (state) => {
        state.isPending = false;
        state.wordCreated = !state.wordCreated;
      })
      .addCase(createWord.pending, (state) => {
        state.isPending = true;
      })
      .addCase(createWord.rejected, (state, action) => {
        state.isPending = false;
        console.log(action.payload);
      })
      .addCase(deleteWord.fulfilled, (state, action) => {
        if (action.payload) {
          state.words.splice(
            state.words.findIndex((word) => word._id === action.payload),
            1
          );
        }
        state.isPending = false;
      })
      .addCase(deleteWord.pending, (state) => {
        state.isPending = true;
      })
      .addCase(deleteWord.rejected, (state, action) => {
        state.isPending = false;
        state.error = { status: 400, message: 'server error' };
        console.log(action.payload);
      })
      .addCase(updateWord.fulfilled, (state, action) => {
        state.isPending = false;
        if (state.words[action.payload.index]._id !== action.payload._id) {
          state.error = { status: 400, message: 'server error' };
          return;
        }
        state.words[action.payload.index].word = action.payload.word;
        state.words[action.payload.index].wordContext = action.payload.wordContext;
        state.words[action.payload.index].translate = action.payload.translate;
        state.words[action.payload.index].translateContext = action.payload.translateContext;
      })
      .addCase(updateWord.pending, (state) => {
        state.isPending = true;
      })
      .addCase(updateWord.rejected, (state, action) => {
        state.isPending = false;
        state.error = { status: 400, message: 'server error' };
        console.log(action.payload);
      });
  },
});

export default dictionarySlice.reducer;
