import {
  Box,
  Divider,
  Drawer,
  FormControl,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Switch,
} from '@mui/material';
import Zoom from '@mui/material/Zoom';
import LogoutOutlinedIcon from '@mui/icons-material/LogoutOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import RepeatOutlinedIcon from '@mui/icons-material/RepeatOutlined';
import ContactSupportOutlinedIcon from '@mui/icons-material/ContactSupportOutlined';
import MenuBookTwoToneIcon from '@mui/icons-material/MenuBookTwoTone';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import { settingsSlice } from 'store/reducers/SettingsSlice';

import React, { FC, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import { signOut } from 'Utils/utils';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';
import { TooltipStyled } from 'components/TooltipStyled/TooltipStyled';

type BurgerMenuProps = {
  isMenuOpen: boolean;
  setMenuOpen: (isMenuOpen: boolean) => void;
};

export const BurgerMenu: FC<BurgerMenuProps> = ({ isMenuOpen, setMenuOpen }) => {
  const { t, i18n } = useTranslation();

  const supportedLanguages = ['en', 'ru'];
  let langInit = i18n.language;
  if (!supportedLanguages.includes(langInit)) {
    langInit = '';
  }
  const [lang, setLang] = useState(langInit);

  const handleChange = (event: SelectChangeEvent) => {
    i18n.changeLanguage(event.target.value as string);
    setLang(event.target.value as string);
  };
  const { isLearnBack, isNeedTips } = useAppSelector((state) => state.settings);
  const { setLearningSide, toggleNeedTips } = settingsSlice.actions;

  const dispatch = useAppDispatch();

  const toggleBurgerMenu = (open: boolean) => () => {
    setMenuOpen(open);
  };
  const handleSideSwitcher = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setLearningSide(event.target.checked));
  };
  const handleTipsSwitcher = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(toggleNeedTips(event.target.checked));
  };
  const logOutHandler = () => {
    signOut();
  };
  const list = (
    <Box sx={{ width: '85vw', mt: 10 }}>
      {isNeedTips && (
        <List>
          <ListItem disablePadding>
            <ListItemIcon>
              <MenuBookTwoToneIcon className="tip__icon" sx={{ ml: 2, mr: 4 }} />
            </ListItemIcon>
            <NavLink to="/help" className="tip__link">
              {t('tips.quickStart')}
            </NavLink>
          </ListItem>
        </List>
      )}
      <Divider sx={{ mt: 1, mb: 1, fontSize: 14 }} textAlign="left">
        {t('settings')}
      </Divider>
      <List>
        {/* <ListItem disablePadding>
          <ListItemButton>
            <ListItemIcon>
              <SettingsOutlinedIcon sx={{ color: '#0288d1' }} />
            </ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItemButton>
        </ListItem> */}

        <ListItem disablePadding>
          {/* <ListItemButton> */}
          <ListItemIcon>
            <RepeatOutlinedIcon sx={{ ml: 2, mr: 4, color: '#0288d1' }} />
          </ListItemIcon>
          {/* <ListItemText primary="Settings" /> */}
          <div className="menu__item-text">
            {t('burgerMenu.translationFirst')}
            {isNeedTips && (
              <TooltipStyled
                enterTouchDelay={0}
                leaveTouchDelay={6000}
                TransitionComponent={Zoom}
                placement="bottom-end"
                title={
                  <span className="tip__tooltip">
                    {t('tips.message.learnModeSwitcherPart1')}
                    <br />
                    <br />
                    {t('tips.message.learnModeSwitcherPart2')}
                    <br />
                    <br />
                    <span className="tip__remark">{t('tips.message.turnOffTips')}</span>
                  </span>
                }
                arrow
              >
                <HelpOutlineIcon
                  sx={{ fontSize: 16, ml: '2px', position: 'absolute' }}
                  className="menu__tip-icon"
                />
              </TooltipStyled>
            )}
          </div>

          <Switch checked={isLearnBack} onChange={handleSideSwitcher} />
          {/* </ListItemButton> */}
        </ListItem>
        <ListItem disablePadding>
          {/* <ListItemButton> */}
          <ListItemIcon>
            <LanguageOutlinedIcon sx={{ ml: 2, mr: 4, color: '#0288d1' }} />
          </ListItemIcon>
          {/* <ListItemText primary="Settings" /> */}
          <ListItemText className="menu__item-text" primary={t('burgerMenu.language')} />
          <FormControl size="small" sx={{ ml: 'auto' }}>
            <Select
              id="lang-change"
              size="small"
              value={lang}
              displayEmpty
              sx={{
                mr: 1.5,
                borderRadius: '8px',
                '& .MuiSelect-select': {
                  padding: '5px 14px',
                },
              }}
              inputProps={{ 'aria-label': 'Without label' }}
              // renderValue={(selected) => {
              //   console.log(selected);

              //   if (selected.length === 0) {
              //     return <>Choose</>;
              //   }
              //   return selected;
              // }}
              onChange={handleChange}
            >
              <MenuItem disabled value="">
                {t('burgerMenu.choose')}
              </MenuItem>
              <MenuItem value="ru">Русский</MenuItem>
              <MenuItem value="en">English</MenuItem>
            </Select>
          </FormControl>
        </ListItem>
        <ListItem disablePadding>
          {/* <ListItemButton> */}
          <ListItemIcon>
            <ContactSupportOutlinedIcon sx={{ ml: 2, mr: 4, color: '#0288d1' }} />
          </ListItemIcon>
          {/* <ListItemText primary="Settings" /> */}
          <ListItemText className="menu__item-text" primary={t('burgerMenu.showTips')} />

          <Switch checked={isNeedTips} onChange={handleTipsSwitcher} />
          {/* </ListItemButton> */}
        </ListItem>
      </List>
      <Divider sx={{ mt: 1, mb: 1 }} />
      <List>
        <ListItem disablePadding>
          <ListItemButton onClick={logOutHandler}>
            <ListItemIcon>
              <LogoutOutlinedIcon sx={{ color: '#0288d1' }} />
            </ListItemIcon>
            <ListItemText className="menu__item-text" primary={t('sign.logOut')} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Drawer anchor="right" open={isMenuOpen} onClose={toggleBurgerMenu(false)}>
      {list}
    </Drawer>
  );
};
