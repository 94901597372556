import { Box, Drawer } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'hooks/redux';
import React from 'react';
import { settingsSlice } from 'store/reducers/SettingsSlice';
import CloseIcon from '@mui/icons-material/Close';

export const HelpDrawer = () => {
  const isHelpOpen = useAppSelector((state) => state.settings.isHelpOpen);
  const dispatch = useAppDispatch();
  const { toggleHelpDrawer } = settingsSlice.actions;
  const helpPage = (
    <Box sx={{ height: '85vh', m: 1 }}>
      <CloseIcon
        className="help-drawer__close-icon"
        sx={{ fontSize: 48 }}
        onClick={() => dispatch(toggleHelpDrawer(false))}
      />
      The best way to user this app The best way to user this app The best way to user this app The
      best way to user this app The best way to user this app The best way to user this app The best
      way to user this app The best way to user this app The best way to user this app The best way
      to user this app The best way to user this app The best way to user this app The best way to
      user this app The best way to user this app The best way to user this app The best way to user
      this app The best way to user this app The best way to user this app The best way to user this
      app The best way to user this app The best way to user this app The best way to user this app
      The best way to user this app The best way to user this app The best way to user this app The
      best way to user this app The best way to user this app The best way to user this app The best
      way to user this app The best way to user this app The best way to user this app The best way
      to user this app The best way to user this app The best way to user this app The best way to
      user this app The best way to user this app The best way to user this app The best way to user
      this app The best way to user this app The best way to user this app The best way to user this
      app The best way to user this app The best way to user this app The best way to user this app
      The best way to user this app The best way to user this app The best way to user this app The
      best way to user this app The best way to user this app The best way to user this app The best
      way to user this app The best way to user this app The best way to user this app The best way
      to user this app The best way to user this app The best way to user this app The best way to
      user this app The best way to user this app The best way to user this app The best way to user
      this app The best way to user this app The best way to user this app The best way to user this
      app The best way to user this app The best way to user this app The best way to user this app
      The best way to user this app The best way to user this app The best way to user this app The
      best way to user this app The best way to user this app The best way to user this app The best
      way to user this app The best way to user this app The best way to user this app The best way
      to user this app The best way to user this app The best way to user this app The best way to
      user this app The best way to user this app The best way to user this app The best way to user
      this app The best way to user this app The best way to user this app
    </Box>
  );

  return (
    <Drawer anchor="bottom" open={isHelpOpen} onClose={() => dispatch(toggleHelpDrawer(false))}>
      {helpPage}
    </Drawer>
  );
};
