import React from 'react';
import { ReactComponent as LogoFull } from '../../assets/svg/logoFull.svg';
import { NavLink } from 'react-router-dom';

export const Help = () => {
  return (
    <div className="help">
      <div className="help__logo">
        <LogoFull />
      </div>
      <h1 className="help__title">How it works.</h1>
      <ul className="help__main-links-block">
        <li>
          <NavLink to="#" className="help__link">
            Quick start
          </NavLink>
        </li>
      </ul>

      <h2>Wanna know more?</h2>
    </div>
  );
};
