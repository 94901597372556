import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authReducer from './reducers/AuthSlice';
import settingsReducer from './reducers/SettingsSlice';
import dictionaryReducer from './reducers/DictionarySlice';
import learnReducer from './reducers/LearnSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  settings: settingsReducer,
  dictionary: dictionaryReducer,
  learn: learnReducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
