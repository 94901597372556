import { createAsyncThunk } from '@reduxjs/toolkit';
import { Word, WordExt, WordDto, AllUserData } from 'types/types';
import { api } from 'http/http';
import { RootState } from 'store/store';
import { ApiError } from 'http/ApiError';

export const getAllWords = createAsyncThunk<WordExt[], undefined, { state: RootState }>(
  'dictionary/fetchWords',
  async (_, thunkAPI) => {
    try {
      const response = await api.get('/words');
      return response.data;
    } catch (error) {
      if (error instanceof ApiError) return thunkAPI.rejectWithValue(error.message);
      return thunkAPI.rejectWithValue("Can't get words");
    }
  },
  {
    condition: (_, { getState }) => {
      const { dictionary } = getState();
      return !dictionary.isPending;
    },
  }
);
export const fetchUserData = createAsyncThunk<AllUserData, undefined, { state: RootState }>(
  'dictionary/fetchUserData',
  async (_, thunkAPI) => {
    try {
      const response = await api.get('/user');
      return response.data;
    } catch (error) {
      if (error instanceof ApiError) return thunkAPI.rejectWithValue(error.message);
      return thunkAPI.rejectWithValue("Can't fetch user data");
    }
  },
  {
    condition: (_, { getState }) => {
      const { dictionary } = getState();
      return !dictionary.isPending;
    },
  }
);

export const changeCurrentDict = createAsyncThunk(
  'dictionary/changeCurrentDict',
  async (dictId: string, thunkAPI) => {
    try {
      await api.post(`/dicts/setCurrent/${dictId}`);
      return dictId;
    } catch (error) {
      if (error instanceof ApiError) return thunkAPI.rejectWithValue(error.message);
      return thunkAPI.rejectWithValue("Can't change current dictionary");
    }
  }
);

export const createDict = createAsyncThunk(
  'dictionary/createDict',
  async (name: string, thunkAPI) => {
    try {
      const response = await api.post('/dicts/create', { name });
      return response.data;
    } catch (error) {
      if (error instanceof ApiError) return thunkAPI.rejectWithValue(error.message);
      return thunkAPI.rejectWithValue("Can't create new dictionary");
    }
  }
);

export const createWord = createAsyncThunk(
  'dictionary/createWord',
  async (word: Word, thunkAPI) => {
    try {
      const response = await api.post('/words/create', word);
      return response.data;
    } catch (error) {
      if (error instanceof ApiError) return thunkAPI.rejectWithValue(error.message);
      return thunkAPI.rejectWithValue("Can't create word");
    }
  }
);

export const deleteWord = createAsyncThunk(
  'dictionary/deleteWord',
  async (word: WordExt, thunkAPI) => {
    try {
      await api.delete(`/words/${word._id}`);
      return word._id;
    } catch (error) {
      if (error instanceof ApiError) return thunkAPI.rejectWithValue(error.message);
      return thunkAPI.rejectWithValue("Can't delete word");
    }
  }
);

export const updateWord = createAsyncThunk(
  'dictionary/updateWord',
  async (word: WordDto, thunkAPI) => {
    try {
      await api.put(`/words/${word._id}`, word);
      return word;
    } catch (error) {
      if (error instanceof ApiError) return thunkAPI.rejectWithValue(error.message);
      return thunkAPI.rejectWithValue("Can't update word");
    }
  }
);
