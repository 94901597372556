import { WordDto } from 'types/types';

export default (word: WordDto): WordDto => {
  return {
    _id: word._id,
    index: word.index,
    word: word.word,
    wordContext: word.wordContext,
    translate: word.translate,
    translateContext: word.translateContext,
  };
};
