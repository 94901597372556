import { saveToken } from './storage';
import axios from 'axios';

export const signOut = async () => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_BASE_URL}/auth/logout`, {
      withCredentials: true,
    });
    console.log(response);
  } catch (error) {}
  saveToken('');

  window.location.replace('/signin');
};
