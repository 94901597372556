import { createSlice } from '@reduxjs/toolkit';
import { signIn, signUp } from './AuthActionCreators';
import { saveToken, getToken } from 'Utils/storage';

export interface AuthState {
  isAuth: boolean;
  isError: boolean;
  errorMessage: string;
  isPending: boolean;
  isSuccess: boolean;
}

const initialState: AuthState = {
  isAuth: !!getToken(),
  isError: false,
  errorMessage: '',
  isPending: false,
  isSuccess: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    // signIn: (state) => {
    //   state.isAuth = true;
    // },
    closeError: (state) => {
      state.isError = false;
    },
    closeSuccess: (state) => {
      state.isSuccess = false;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(signUp.fulfilled, (state) => {
        state.isSuccess = true;
        state.isPending = false;
      })
      .addCase(signUp.pending, (state) => {
        state.isPending = true;
      })
      .addCase(signUp.rejected, (state, action) => {
        state.isAuth = false;
        state.isPending = false;
        state.isError = true;
        state.errorMessage = action.payload as string;
      })
      .addCase(signIn.fulfilled, (state, action) => {
        if (action.payload.token) {
          saveToken(action.payload.token);
          state.isAuth = true;
          state.isPending = false;
          state.isError = false;
          state.errorMessage = '';
        } else {
          state.isAuth = false;
          state.isPending = false;
          state.isError = true;
          state.errorMessage = 'Something went wrong';
        }
      })
      .addCase(signIn.rejected, (state, action) => {
        state.isAuth = false;
        state.isPending = false;
        state.isError = true;
        state.errorMessage = action.payload as string;
      });
  },
});

export default authSlice.reducer;
